
































import { Component, Vue } from "vue-property-decorator";
import fb from "@/plugins/firebase";
import "@/types";

@Component
export default class HomeBanner extends Vue {
  banners = [] as Banner[];

  slideIndex = 0;

  get height() {
    switch (this.$vuetify.breakpoint.name) {
      case "sm":
        return 300;
      case "md":
        return 600;
      case "lg":
        return 600;
      case "xl":
        return 600;
      default:
        return 300;
    }
  }

  slideChanged(index: number) {
    this.slideIndex = index;
  }

  async getBanners() {
    try {
      const query = await fb
        .firestore()
        .collection("/apps/kahev-org/banners-en")
        .where("disabled", "==", false)
        .orderBy("dateCreated", "desc")
        .get();

      if (!query.empty) {
        this.banners = [];

        query.docs.forEach(doc => {
          const banner = {
            id: doc.id,
            title: doc.data().title,
            text: doc.data().text,
            img: doc.data().img,
            dateCreated: doc.data().dateCreated.toDate(),
            disabled: doc.data().disabled,
            preTitle: doc.data().preTitle,
            link: doc.data().link,
            linkTitle: doc.data().linkTitle
          } as Banner;

          this.banners.push(banner);
        });
      }
    } catch (e) {
      const err = e as firebase.default.FirebaseError;
      console.error(`Bannerlar yüklenemedi. ${err.message}`);
    }
  }

  async mounted() {
    await this.getBanners();
  }
}
