































import { Component, Vue } from "vue-property-decorator";

@Component
export default class OurVision extends Vue {
  items = [
    {
      img: require("@/assets/mission-1.svg"),
      title: "Scholarship",
      text:
        "We provide unpaid scholarships to all learning enthusiasts in need, from primary education to university."
    },
    {
      img: require("@/assets/mission-2.svg"),
      title: "Uninterrupped",
      text:
        "KAHEV has always taken it upon itself to support our children and youth, who are the bright faces of our future."
    },
    {
      img: require("@/assets/mission-3.svg"),
      title: "Wholeheartedly",
      text:
        "We offer equal opportunities in education by building bridges from heart to heart with the contributions of you, our esteemed supporters."
    }
  ];
}
