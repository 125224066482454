
















import { Component, Vue } from "vue-property-decorator";
import Banner from "./Banner.vue";
import About from "./About.vue";
import OurMission from "./OurMission.vue";
import News from "./News.vue";
import International from "./International.vue";

@Component({
  components: {
    Banner,
    About,
    OurMission,
    News,
    International
  }
})
export default class HomeView extends Vue {}
